<script setup lang="ts">
const prepareImgUrl = useImage();
const { t } = useT();

const emit = defineEmits<{ (event: "click"): void }>();

const handleClick = () => {
	emit("click");
};
</script>

<template>
	<div class="activity-bingo" @click="handleClick">
		<picture>
			<source
				:srcset="
					prepareImgUrl('/nuxt-img/bingo/mob-activity-img.webp', {
						format: 'avif',
						width: 320,
						height: 216
					})
				"
				media="(max-width: 767px)"
			/>
			<NuxtImg
				class="image"
				src="/nuxt-img/bingo/desk-activity-img.webp"
				format="avif"
				width="326"
				height="340"
				alt="bingo"
				loading="lazy"
			/>
		</picture>
		<ABadge variant="skew" background="var(--carrara)" autosize>
			<AText variant="topeka" :modifiers="['bold', 'uppercase']">
				{{ t("Bingo lotto") }}
			</AText>
		</ABadge>
		<div class="content">
			<AText class="label text-cannes" variant="topeka" :modifiers="['bold', 'uppercase']">
				<i18n-t keypath="max{delimiter}win">
					<template #delimiter><br /></template>
				</i18n-t>
			</AText>
			<div class="prize-pool">
				<MPrizeFund variant="coins" icon="16/coins" :iconSize="24">
					<AText :size="20" :modifiers="['semibold']">
						{{ t("1,000,000") }}
					</AText>
				</MPrizeFund>
				<MPrizeFund variant="entries" icon="20/entries" :iconSize="24">
					<AText :size="20" :modifiers="['semibold']">
						{{ t("10,000") }}
					</AText>
				</MPrizeFund>
			</div>
		</div>
		<AButton variant="primary" :modifiers="['wide']">
			<AText :size="16" :modifiers="['uppercase', 'semibold']">
				{{ t("Play now") }}
			</AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.activity-bingo.item {
	justify-content: space-between;

	@include media-breakpoint-down(md) {
		padding: 112px 0 12px;
		align-items: center;
	}

	.badge {
		position: absolute;
		left: 0;
		top: 0;
	}

	.content {
		position: relative;
		display: flex;
		align-items: center;
		gap: 4px;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			justify-content: center;
		}
	}

	.label {
		margin-right: 4px;

		@include media-breakpoint-down(md) {
			text-align: center;
			margin-right: 0;

			br {
				display: none;
			}
		}
	}

	button {
		max-width: 165px;

		@include media-breakpoint-down(md) {
			max-width: 135px;

			span {
				font-size: 14px;
			}
		}
	}

	.prize-pool {
		display: flex;
		gap: 2px;
		flex-direction: column;

		&:deep(.prize) {
			@include media-breakpoint-down(md) {
				align-items: center;

				span {
					font-size: 16px;
				}

				.nuxt-icon {
					font-size: 20px;
				}
			}
		}
	}

	.image {
		position: absolute;
		right: 0;
		top: 0;
		width: 163px;
		height: 170px;

		@include media-breakpoint-down(md) {
			width: 175px;
			left: 50%;
			transform: translateX(-50%);
			height: auto;
		}
	}
}
</style>
